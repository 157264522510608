import firebase from 'firebase/app';
import 'firebase/auth';

// Initialize Firebase
// fill in API key
const config = {
  apiKey: 'AIzaSyAzvP0Qqzcpz-4BIDcmSxx4Wz4yg8UVWe8',
  authDomain: 'haydels-bc-integration.firebaseapp.com',
  databaseURL: 'https://haydels-bc-integration.firebaseio.com',
  projectId: 'haydels-bc-integration',
  storageBucket: 'haydels-bc-integration.appspot.com',
  messagingSenderId: '324756639616'
};

firebase.initializeApp(config);


export default firebase;
