import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#fdf5cc',
      main: '#9F3400',
      dark: '#6e4739',
      contrastText: '#fff'
    }
  },
  overrides: {
    MuiTypography: {
      colorPrimary: '#9F3400'
    }
  }
});

export default theme;
