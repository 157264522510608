import React from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  wrapper: {
    marginTop: '1em'
  },
  paper: {
    padding: '1em',
    textAlign: 'center'
  }
});

export default function ErrorPage() {
  const classes = useStyles();
  return (
    <Container className={classes.wrapper}>
      <Paper className={classes.paper}>
        <h1>404 Error</h1>
        <p>Sorry, this page is missing.</p>
      </Paper>
    </Container>
  );
}
