import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import {
  BrowserRouter as Router, Route, Switch
} from 'react-router-dom';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import firebaseui from 'firebaseui';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import muiTheme from './lib/theme';


import FirebaseService from './FirebaseService';
import { ROUTES } from './lib/constants';
import API from './lib/API';

import MainNav from './Components/MainNav';
import Loader from './Components/Loader';


import './App.css';
import LogoImg from '../assets/img/haydel-bakery-logo-new.png';

import Home from './Views/Home';
import ProductionReport from './Views/ProductionReport';
import ErrorPage from './Views/ErrorPage';

class App extends Component {
  state = {
    initalized: false,
    isSignedIn: false,
    signInHeader: 'Please sign in.',
    signInVisibility: 'visible'
  };

  uiConfig = {
    signInFlow: 'popup',
    credentialHelper: firebaseui.auth.CredentialHelper.NONE,
    signInOptions: [
      FirebaseService.auth.EmailAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      signInSuccessWithAuthResult: (e) => {
        if (e.additionalUserInfo && e.additionalUserInfo.isNewUser) {
          this.setState({
            isSignedIn: false,
            signInVisibility: 'hidden',
            signInHeader: 'Your account must be activated by an administrator.'
          });
          return FirebaseService.auth().signOut();
        }
        return null;
      }
    }
  };

  loadingView = (
    <div className="App">
      <div className="centerize">
        <div>
          <img src={LogoImg} alt="Haydel's Bakery" />
          <h1>Loading</h1>
          <Loader />
        </div>
      </div>
    </div>
  );

  componentDidMount() {
    API.runStoreRefresh();
    this.setState(state => ({ ...state, initalized: true }));
    this.unregisterAuthObserver = FirebaseService.auth().onAuthStateChanged(
      async user => this.setState(state => ({ ...state, isSignedIn: Boolean(user) })),
    );
  }

  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  signInView() {
    const { signInHeader, signInVisibility } = this.state;
    return (
      <div className="App">
        <div className="centerize">
          <div>
            <img src={LogoImg} alt="Haydel's Bakery" />
            <h1>{signInHeader}</h1>
            <div className="auth-wrapper" style={{ visiblity: signInVisibility }}>
              <StyledFirebaseAuth
                uiConfig={this.uiConfig}
                firebaseAuth={FirebaseService.auth()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }


  render() {
    const { initalized, isSignedIn } = this.state;
    if (!initalized) {
      return this.loadingView;
    }

    if (initalized && !isSignedIn) {
      return this.signInView();
    }

    return (
      <div className="App">
        <MuiThemeProvider theme={muiTheme}>
          <Router>
            <MainNav />
            <Container className="container">
              <Switch>
                <Route path={ROUTES.home} exact component={Home} />
                <Route path={ROUTES.auth} exact component={Home} />
                <Route path={ROUTES.authLoad} exact component={Home} />
                <Route path={ROUTES.production} component={ProductionReport} />
                <Route component={ErrorPage} />
              </Switch>
            </Container>
          </Router>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default App;
