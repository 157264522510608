import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MUIButton from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { Route } from 'react-router-dom';
import { Button } from '../Components/AdapterLink';
import { ROUTES, URLS } from '../lib/constants';
import CutOffTime from './CutOffTime';

/* const today = new Date();
const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`; */

const useStyles = makeStyles(theme => ({
  root: {
    margin: '20px 0',
    padding: theme.spacing(3, 2),
    display: 'flex',
    flexWrap: 'wrap'
  },
  list: {
    marginBottom: '20px'
  },
  headline: {
    width: '100%'
  },
  group: {
    width: '50%',
    padding: '1em',
    minWidth: '300px'
  },
  form: {
    border: '1px solid rgba(159, 52, 0, 0.5)',
    color: '#9F3400',
    'text-transform': 'uppercase',
    'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
    'font-weight': 500,
    'font-size': '0.875rem'
  },
  select: {
    padding: '10px',
    border: '1px solid rgba(159, 52, 0, 0.5)',
    background: 'transparent'
  }
}));

const simpleMUILinkProps = {
  component: 'a',
  target: '_blank',
  rel: 'noopener noreferrer'
};

export default function Home() {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <h1 className={classes.headline}>Haydel&rsquo;s Bakery Utilities</h1>
      <div className={classes.group}>
        <h2>Daily Production</h2>
        <List className={classes.list}>
          <ListItem>
            <Button color="primary" variant="outlined" {...simpleMUILinkProps} href={`${URLS.todayShippingReport}`}>View today&rsquo;s shipping production Report</Button>
          </ListItem>
          <ListItem>
            <Button color="primary" variant="contained" to={ROUTES.pickupReport}>View today&rsquo;s pickup production Report</Button>
          </ListItem>
          <ListItem>
            <MUIButton
              color="primary"
              variant="contained"
              {...simpleMUILinkProps}
              href="/api/pickup-order-labels"
            >Print Labels For Pickup Items
            </MUIButton>
          </ListItem>
          <ListItem>
            <MUIButton
              color="primary"
              variant="contained"
              {...simpleMUILinkProps}
              href={`${URLS.bulkUploader}`}
            >Upload Bulk Orders
            </MUIButton>
          </ListItem>
          <ListItem>
            <MUIButton
              color="primary"
              variant="contained"
              {...simpleMUILinkProps}
              href={`${URLS.blackoutCalendar}`}
            >Blackout Calendar
            </MUIButton>
          </ListItem>
          <ListItem>
            <MUIButton
              color="primary"
              variant="contained"
              {...simpleMUILinkProps}
              href={`${URLS.saturdayDeliveryCheck}`}
            >Saturday delivery check
            </MUIButton>
          </ListItem>
        </List>
      </div>
      <div className={classes.group}>
        <h2>Website Resources</h2>
        <List className={classes.list}>
          <ListItem>
            <MUIButton
              color="primary"
              variant="outlined"
              {...simpleMUILinkProps}
              href={`${URLS.storeRoot}/manage/app/17269`}
            >Open this app in BigCommerce
            </MUIButton>
          </ListItem>
          <ListItem>
            <MUIButton
              color="primary"
              variant="outlined"
              {...simpleMUILinkProps}
              href={`${URLS.storeRoot}/`}
            >View the Website
            </MUIButton>
          </ListItem>
          <ListItem>
            <MUIButton
              color="primary"
              variant="outlined"
              {...simpleMUILinkProps}
              href={`${URLS.storeRoot}/manage/`}
            >Log in to the Website Admin Portal
            </MUIButton>
          </ListItem>
          <ListItem>
            <Route component={CutOffTime} />
          </ListItem>
        </List>
      </div>
      <div className={classes.group}>
        <h2>Shipstation</h2>
        <p>App that handles shipping operations, including printing shipping labels.</p>
        <List className={classes.list}>
          <ListItem>
            <MUIButton
              color="primary"
              variant="outlined"
              {...simpleMUILinkProps}
              href={`${URLS.shipstation}`}
            >ShipStation Admin Panel
            </MUIButton>
          </ListItem>
        </List>
      </div>
      <div className={classes.group}>
        <h2>ShipperHQ</h2>
        <p>App that sets shipping rates that appear on website frontend</p>
        <List className={classes.list}>
          <ListItem>
            <MUIButton
              color="primary"
              variant="outlined"
              {...simpleMUILinkProps}
              href={`${URLS.storeRoot}/manage/app/4869`}
            >ShipperHQ (log in through BigCommerce)
            </MUIButton>
          </ListItem>
          <ListItem>
            <MUIButton
              color="primary"
              variant="outlined"
              {...simpleMUILinkProps}
              href="https://shipperhq.com/ratesmgr/ratecalc"
            >ShipperHQ Package Rate Calculator
            </MUIButton>
          </ListItem>
        </List>
      </div>
    </Paper>
  );
}
