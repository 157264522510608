import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

import { withStyles } from '@material-ui/core/styles';
import API from '../lib/API';

import '../Components/datepicker.css';

const styles = {
  root: {
    width: '100%',
    margin: '20px 0'
  },
  paper: {
    width: '100%',
    overflowX: 'auto',
    marginTop: '1em'
  },
  paperError: {
    marginTop: '2em',
    padding: '2em'
  },
  paperLoad: {
    marginTop: '2em',
    padding: '2em',
    justifyContent: 'center',
    textAlign: 'center'
  },
  stripedRow: {
    '& td, & th': {
      backgroundColor: '#f3f3f3'
    }
  },
  qtyCol: {
    width: '2em',
    fontWeight: '900'
  },
  skuCol: {
    fontSize: '10px'
  },
  headline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textTransform: 'capitalize'
  },
  datePicker: {
    fontWeight: 'normal',
    fontSize: '.7em'
  },
  form: {
    border: '1px solid rgba(159, 52, 0, 0.5)',
    color: '#9F3400',
    'text-transform': 'uppercase',
    'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
    'font-weight': 500,
    'font-size': '0.875rem'
  },
  select: {
    padding: '10px',
    border: '1px solid rgba(159, 52, 0, 0.5)',
    background: 'transparent'
  },
  orderCutOffTime: {
    display: 'inline-block',
    width: '170px',
    'text-align': 'center',
    'font-size': '26px',
    float: 'right'
  }
};

class CutOffTime extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.state = {
      hourAndMin: { hour: '00', min: '00' },
      hours: [],
      mins: [],
      oldHourAndMin: { hour: '00', min: '00' },
      orderCutOffTimeUrl: 'https://veyron.eyemaginetech.com/haydels/OrderCutoffTime.php?prod=1'
    };
  }

  componentDidMount() {
    this.fetchCutOffTimeData();
    // hostname
    const hostNameUrl = window.location.hostname;
    switch (hostNameUrl) {
      case 'localhost':
      case 'hb.local.build':
        this.setState({ orderCutOffTimeUrl: 'http://big.local.build/OrderCutoffTime.php?prod=0' });
        break;
      default:
        // equivalent to the last else clause ...
    }
  }

  // eslint-disable-next-line react/sort-comp
  fetchCutOffTimeData() {
    API.getCutOffTime()
      .then((data) => {
        this.setState(state => ({
          ...state,
          hourAndMin: data,
          oldHourAndMin: data,
          hours: [
            { id: '00', name: '12 AM' },
            { id: '01', name: '01 AM' },
            { id: '02', name: '02 AM' },
            { id: '03', name: '03 AM' },
            { id: '04', name: '04 AM' },
            { id: '05', name: '05 AM' },
            { id: '06', name: '06 AM' },
            { id: '07', name: '07 AM' },
            { id: '08', name: '08 AM' },
            { id: '09', name: '09 AM' },
            { id: '10', name: '10 AM' },
            { id: '11', name: '11 AM' },
            { id: '12', name: '12 PM' },
            { id: '13', name: '01 PM' },
            { id: '14', name: '02 PM' },
            { id: '15', name: '03 PM' },
            { id: '16', name: '04 PM' },
            { id: '17', name: '05 PM' },
            { id: '18', name: '06 PM' },
            { id: '19', name: '07 PM' },
            { id: '20', name: '08 PM' },
            { id: '21', name: '09 PM' },
            { id: '22', name: '10 PM' },
            { id: '23', name: '11 PM' }
          ],
          mins: [
            { id: '00', name: '00' },
            { id: '01', name: '01' },
            { id: '02', name: '02' },
            { id: '03', name: '03' },
            { id: '04', name: '04' },
            { id: '05', name: '05' },
            { id: '06', name: '06' },
            { id: '07', name: '07' },
            { id: '08', name: '08' },
            { id: '09', name: '09' },
            { id: '10', name: '10' },
            { id: '11', name: '11' },
            { id: '12', name: '12' },
            { id: '13', name: '13' },
            { id: '14', name: '14' },
            { id: '15', name: '15' },
            { id: '16', name: '16' },
            { id: '17', name: '17' },
            { id: '18', name: '18' },
            { id: '19', name: '19' },
            { id: '20', name: '20' },
            { id: '21', name: '21' },
            { id: '22', name: '22' },
            { id: '23', name: '23' },
            { id: '24', name: '24' },
            { id: '25', name: '25' },
            { id: '26', name: '26' },
            { id: '27', name: '27' },
            { id: '28', name: '28' },
            { id: '29', name: '29' },
            { id: '30', name: '30' },
            { id: '31', name: '31' },
            { id: '32', name: '32' },
            { id: '33', name: '33' },
            { id: '34', name: '34' },
            { id: '35', name: '35' },
            { id: '36', name: '36' },
            { id: '37', name: '37' },
            { id: '38', name: '38' },
            { id: '39', name: '39' },
            { id: '40', name: '40' },
            { id: '41', name: '41' },
            { id: '42', name: '42' },
            { id: '43', name: '43' },
            { id: '44', name: '44' },
            { id: '45', name: '45' },
            { id: '46', name: '46' },
            { id: '47', name: '47' },
            { id: '48', name: '48' },
            { id: '49', name: '49' },
            { id: '50', name: '50' },
            { id: '51', name: '51' },
            { id: '52', name: '52' },
            { id: '53', name: '53' },
            { id: '54', name: '54' },
            { id: '55', name: '55' },
            { id: '56', name: '56' },
            { id: '57', name: '57' },
            { id: '58', name: '58' },
            { id: '59', name: '59' }
          ]
        }));
      })
      .catch(() => {
      });
  }

  handleChange = (event) => {
    this.setState({ hourAndMin: { [event.target.name]: event.target.value } });
  }

  render() {
    const { classes = { params: { option: 'shipping' } } } = this.props;
    const {
      hourAndMin, hours, mins, oldHourAndMin, orderCutOffTimeUrl
    } = this.state;

    // get hours
    const hoursList = hours.length > 0
        && hours.map(item => (
          <option value={item.id} key={item.id}>{item.name}</option>
        ), this);

    // get mins
    const minsList = mins.length > 0
        && mins.map(item => (
          <option value={item.id} key={item.id}>{item.name}</option>
        ), this);

    const hourSelected = hourAndMin.hour;
    const minSelected = hourAndMin.min;

    return (
      <form action={orderCutOffTimeUrl} method="post">
        <fieldset className={classes.form}>
          <legend>Order Cutoff Time</legend>
          <br />
            Hour: &nbsp;&nbsp;
          <select value={hourSelected} name="hour" className={classes.select} onChange={this.handleChange}>
            { hoursList }
          </select>
            &nbsp;&nbsp;MIN: &nbsp;&nbsp;
          <select value={minSelected} name="min" className={classes.select} onChange={this.handleChange}>
            { minsList }
          </select>
          <br /><br />
          <input name="save" value="Save" type="submit" className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary" />
          <div className={classes.orderCutOffTime}>{oldHourAndMin.hour}:{oldHourAndMin.min}</div>
        </fieldset>
      </form>
    );
  }
}

export default withStyles(styles)(CutOffTime);
