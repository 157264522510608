import axios from 'axios';
import { DateTime } from 'luxon';
import { FORMAT_REQUEST } from './constants';

async function runStoreRefresh() {
  return axios.get('/api/refresh-store?action=run', { withCredentials: true })
    .catch(error => error);
}

async function getProductionReport(option, reportDate) {
  const fetchDate = reportDate ?
    reportDate.toFormat(FORMAT_REQUEST) :
    DateTime.local().toFormat(FORMAT_REQUEST);

  return axios.get(`/api/production-report/${option}?date=${fetchDate}`, { withCredentials: true })
    .then((resp) => {
      if (resp.data && typeof resp.data === 'object') {
        return resp.data;
      }
      throw new Error('Data Malformatted');
    })
    .catch(error => error);
}

async function getCutOffTime() {
  return axios({
    method: 'get',
    url: 'https://veyron.eyemaginetech.com/haydels/cutOffTime.json',
    dataType: 'json'
  })
    .then((resp) => {
      if (resp) {
        return resp.data;
      }
      throw new Error('Data Malformatted');
    })
    .catch(error => error);
}
export default {
  runStoreRefresh,
  getProductionReport,
  getCutOffTime
};
