import React from 'react';
import { Link as RouterLink, NavLink as RouterNavLink } from 'react-router-dom';
import MUIButton from '@material-ui/core/Button';
import MUILink from '@material-ui/core/Link';

const AdapterLink = React.forwardRef(
  (props, ref) => <RouterLink innerRef={ref} {...props} />
);
const AdapterNavLink = React.forwardRef(
  (props, ref) => <RouterNavLink innerRef={ref} {...props} />
);

function Button(props) {
  return <MUIButton component={AdapterLink} {...props} />;
}

function Link(props) {
  return <MUILink component={AdapterLink} {...props} />;
}

function NavButton(props) {
  return <MUIButton component={AdapterNavLink} {...props} />;
}

function NavLink(props) {
  return <MUILink component={AdapterNavLink} {...props} />;
}

export {
  AdapterLink, AdapterNavLink, Button, Link, NavButton, NavLink
};
