// const { BIGCOMMERCE_NAME } = require('../../../functions/config/bigcommerceConfig');

export const FORMAT = 'yyyyMMdd';
export const FORMAT_REQUEST = 'yyyy-MM-dd';

export const ROUTES = {
  home: '/',
  auth: '/auth',
  authLoad: '/auth/load',
  production: '/production/:option',
  shippingReport: '/production/shipping',
  pickupReport: '/production/pickup'
};

// const isStaging = BIGCOMMERCE_NAME === 'Haydel\'s Staging';
// const storeRoot = isStaging ? 'https://storyblock.mybigcommerce.com' : 'https://haydelsbakery.com';
// const shipstationURL = isStaging ? 'https://ss11.shipstation.com' : 'https://ss9.shipstation.com';
export const URLS = {
  storeRoot: 'https://haydelsbakery.com',
  shipperHQ: 'https://shipperhq.com/',
  shipstation: 'https://ss9.shipstation.com',
  bulkUploader: 'https://veyron.eyemaginetech.com/haydels/index.php?prod=1',
  blackoutCalendar: 'https://veyron.eyemaginetech.com/haydels/calendar/',
  saturdayDeliveryCheck: 'https://wwwapps.ups.com/time',
  todayShippingReport: 'https://veyron.eyemaginetech.com/haydels/productionreport.php?prod=1'
};
