import React, { Component } from 'react';
import { DateTime } from 'luxon';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import Loader from '../Components/Loader';
import API from '../lib/API';

import '../Components/datepicker.css';

const styles = {
  root: {
    width: '100%',
    margin: '20px 0'
  },
  paper: {
    width: '100%',
    overflowX: 'auto',
    marginTop: '1em'
  },
  paperError: {
    marginTop: '2em',
    padding: '2em'
  },
  paperLoad: {
    marginTop: '2em',
    padding: '2em',
    justifyContent: 'center',
    textAlign: 'center'
  },
  stripedRow: {
    '& td, & th': {
      backgroundColor: '#f3f3f3'
    }
  },
  qtyCol: {
    width: '2em',
    fontWeight: '900'
  },
  skuCol: {
    fontSize: '10px'
  },
  headline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textTransform: 'capitalize'
  },
  datePicker: {
    fontWeight: 'normal',
    fontSize: '.7em'
  }
};

const DEFAULT_REPORT_DATA = { loading: { sku: 'loading...', name: 'loading...' } };

class ProductionReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadedOption: null,
      startDate: new Date(),
      reportDate: DateTime.fromISO(new Date().toISOString()),
      reportInfo: null,
      reportData: DEFAULT_REPORT_DATA
    };
  }

  componentDidMount() {
    const { match: { params: { option } } } = this.props;
    this.fetchReportData(option);
  }

  componentDidUpdate(prevProps) {
    const { option: prevOption } = prevProps.match.params;
    const { match: { params: { option } } } = this.props;
    if (option !== prevOption) {
      this.setState(state => ({ ...state, reportData: DEFAULT_REPORT_DATA }));
      this.fetchReportData(option);
    }
  }

  handleDateChange = (date) => {
    const newReportDate = DateTime.fromISO(date.toISOString());
    this.setState({
      startDate: date,
      reportDate: newReportDate
    }, () => {
      this.setState(state => ({ ...state, reportData: DEFAULT_REPORT_DATA }));
      this.fetchReportData();
    });
  };

  fetchReportData(option = 'shipping') {
    const { reportDate } = this.state;
    API.getProductionReport(option, reportDate)
      .then((data) => {
        this.setState(state => ({
          ...state,
          reportData: { ...data.items },
          reportInfo: { ...data.info }
        }));
      })
      .catch(() => {
        this.setState({
          reportData: { error: { name: 'error loading data', sku: '[error]' } }
        });
      });
  }

  render() {
    const productionReportStartDate = DateTime.local().minus({ days: 7 }).toJSDate();
    const { classes, match = { params: { option: 'shipping' } } } = this.props;
    const reportType = match.params.option;
    const {
      reportData,
      reportDate,
      reportInfo = { totalItems: 0 },
      startDate
    } = this.state;

    const sorter = (a, b) => {
      if (a.sku < b.sku) { return -1; }
      if (a.sku > b.sku) { return 1; }
      return 0;
    };

    const onPrint = (e) => {
      window.print();
      e.preventDefault();
    };

    if (reportData.error) {
      return (
        <Paper className={`${classes.paper} ${classes.paperError} production-report`}>
          <h1>
            There was an error loading the production report for {reportDate.toLocaleString()}.
          </h1>
          <p>Please refresh the page and try again. If the issue persists, contact tech support.</p>
        </Paper>
      );
    }

    if (reportData.loading) {
      return (
        <Container className={classes.root}>
          <h1 className={classes.headline}>
            <span>{reportDate.toLocaleString()} {reportType} Production Report</span>
          </h1>
          <Paper className={`${classes.paper} ${classes.paperLoad} production-report`}>
            <h2>Loading...</h2>
            <Loader />
          </Paper>
        </Container>
      );
    }

    return (
      <Container className={classes.root}>
        <h1 className={classes.headline}>
          <span>{reportDate.toLocaleString()} {reportType} Production Report </span>
          <span className={classes.datePicker}>
            Date: <DatePicker selected={startDate} onChange={this.handleDateChange} dateFormat="MM/dd/yyyy" minDate={productionReportStartDate} />
          </span>
          <Button color="primary" variant="contained" className="hide-for-print" onClick={onPrint}>Print &raquo;</Button>
        </h1>

        <Paper className={`${classes.paper} production-report`}>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell align="right" className={classes.qtyCol}>Quantity ({reportInfo.totalItems})</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>SKU</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(reportData).sort(sorter).map((item, i) => (
                <TableRow key={`${item.name} ${item.sku}`} className={i % 2 === 0 ? classes.stripedRow : ''}>
                  <TableCell align="right" className={classes.qtyCol}>{item.quantity || ''}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell component="th" scope="row" className={classes.skuCol}>{item.sku || '[no sku]'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Container>
    );
  }
}

export default withStyles(styles)(ProductionReport);
