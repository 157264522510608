import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import logoImg from '../../assets/img/haydel-bakery-logo-new.png';
import { ROUTES } from '../lib/constants';
import { Button, NavButton } from './AdapterLink';

const useStyles = makeStyles(theme => ({
  mainNav: {
    flexGrow: 1,
    '& .active': {
      border: '1px solid'
    }
  },
  headerLogo: {
    height: '60px',
    margin: '0.5em 1em 0.5em 0'
  },
  appBar: {
    backgroundColor: '#fdf5cc',
    color: '#9F3400'
  },
  menuButton: {
    marginRight: theme.spacing(2),
    border: '1px solid #fdf5cc'
  },
  title: {
    flexGrow: 1,
    fontSize: '20px'
  }
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  return (
    <div id="mainNav" className={classes.mainNav}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <img src={logoImg} alt="Haydel's Bakery" className={classes.headerLogo} />
          <Typography variant="h1" className={classes.title}>
            Haydel&rsquo;s Bakery Utils
          </Typography>
          <Button to={ROUTES.home} className={classes.menuButton} color="inherit">Home</Button>
          <NavButton to={ROUTES.shippingReport} className={classes.menuButton} color="inherit">Shipping Production Report</NavButton>
          <NavButton to={ROUTES.pickupReport} className={classes.menuButton} color="inherit">Pickup Production Report</NavButton>
        </Toolbar>
      </AppBar>
    </div>
  );
}
